@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300&display=swap');

.aboutContainer{
  padding-top: 54px;
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  height: calc(100vh - 54px);
  width: 85vw;
  margin:0 auto;
}
.innerContainer{
  display:flex;
  gap:5vw;
}
.myselfImage{
  width: 80vw;
  max-width: 320px;
  border-radius: 8px;
}

.aboutContent > p {
  margin:0;
  line-height: 160%;
  font-size:clamp(16px, 1.4vw,20px);
  max-width: 50ch;
  color:#323232d0;
  margin-block-end: 0.5rem;
}
@media screen and (min-width:768px) {
  .aboutContent >p{
    font-size: clamp(12px,1.8vw, 22px);
  }
}
@media screen and (max-width:768px){
  .aboutContainer{
    height: auto;
  }
  .myselfImage{
    width: 70vw;
    display: block;
    margin:0 auto;
  }
  .aboutContent{
    margin:0 auto;
    max-width: 90vw;
  }
}
@media screen and (max-width:725px){
  .innerContainer{
    display:flex;
    flex-direction: column-reverse;
  }
}

