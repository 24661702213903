.projectContainer{
  padding-top: 54px;
  position: relative;
  width: 85vw;
  margin:0 auto;
}
.projectList{
  list-style: none;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.techList{
  color:cornflowerblue
}
.nameAndLinks{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.iconLink{
  color:rgb(60, 60, 60);
}
.iconLink:hover{
  color:rgb(118, 118, 118);
}

@media screen and (max-width:600px){
  .projectContainer{
    width:90vw;
  }
  .projectList{   
    margin:0 auto;
    width: 90vw;
  }
 
}
