*{
  box-sizing: border-box;
}
body {
  background-color: #e6e5dd;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
 
main{
  margin:0 auto;
  width: 100%;
}
.sectionHeader{
  font-size: 1.2rem;
  letter-spacing: 1px;
  text-transform: uppercase;
  text-align: left;
  color:#323232d0;
  font-family:Arial, Helvetica, sans-serif;
}

@media screen and (max-width: 750px){
  .sectionHeader{
    text-align:center;
    margin-bottom:0;
  }
}



