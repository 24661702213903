@import url(https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Space+Mono&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);
*{
  box-sizing: border-box;
}
body {
  background-color: #e6e5dd;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
 
main{
  margin:0 auto;
  width: 100%;
}
.sectionHeader{
  font-size: 1.2rem;
  letter-spacing: 1px;
  text-transform: uppercase;
  text-align: left;
  color:#323232d0;
  font-family:Arial, Helvetica, sans-serif;
}

@media screen and (max-width: 750px){
  .sectionHeader{
    text-align:center;
    margin-bottom:0;
  }
}




.aboutContainer{
  padding-top: 54px;
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  height: calc(100vh - 54px);
  width: 85vw;
  margin:0 auto;
}
.innerContainer{
  display:flex;
  grid-gap:5vw;
  gap:5vw;
}
.myselfImage{
  width: 80vw;
  max-width: 320px;
  border-radius: 8px;
}

.aboutContent > p {
  margin:0;
  line-height: 160%;
  font-size:clamp(16px, 1.4vw,20px);
  max-width: 50ch;
  color:#323232d0;
  -webkit-margin-after: 0.5rem;
          margin-block-end: 0.5rem;
}
@media screen and (min-width:768px) {
  .aboutContent >p{
    font-size: clamp(12px,1.8vw, 22px);
  }
}
@media screen and (max-width:768px){
  .aboutContainer{
    height: auto;
  }
  .myselfImage{
    width: 70vw;
    display: block;
    margin:0 auto;
  }
  .aboutContent{
    margin:0 auto;
    max-width: 90vw;
  }
}
@media screen and (max-width:725px){
  .innerContainer{
    display:flex;
    flex-direction: column-reverse;
  }
}


.landingContainer{
  display:flex;
  flex-direction: column;
  justify-content: center;
  grid-gap:3rem;
  gap:3rem;
  align-items:center;
  height: 100vh;
  width: 85vw;
  margin:0 auto;
  color:#555
}
.greeting{
  font-size: clamp(1.8em,4vw,2.5rem);
  color:rgba(74, 74, 74, 0.728);
  margin:0rem;
}
.name{
  font-size: clamp(2.4rem,8vw, 5rem);
  text-align: left;
  color:#e1bea9f7;
  line-height: 90%;
  margin:0.5rem 0rem 1rem 0rem;
}
.statement{
  color:rgba(74, 74, 74, 0.728);
  margin:0;
  font-size: clamp(1.6rem,4vw,2.5rem);
  line-height: clamp(1.8rem,150%, 3.4rem);
  padding-bottom: 3rem;
  /* line-height: clamp(1.8rem,3.5vh, 4rem); */
}
.chevronDiv{
  cursor: pointer;
  position: absolute;
  bottom:0;
}
.iconFlex{
  display: flex;
}
.icon{
  font-size: 1.8em;
  margin-right: 0.4em;
}


.projectContainer{
  padding-top: 54px;
  position: relative;
  width: 85vw;
  margin:0 auto;
}
.projectList{
  list-style: none;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.techList{
  color:cornflowerblue
}
.nameAndLinks{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.iconLink{
  color:rgb(60, 60, 60);
}
.iconLink:hover{
  color:rgb(118, 118, 118);
}

@media screen and (max-width:600px){
  .projectContainer{
    width:90vw;
  }
  .projectList{   
    margin:0 auto;
    width: 90vw;
  }
 
}

.contactContainer{
    position: relative;
    padding-top: 54px;
    width: 90vw;
    margin:0 auto;
    height: calc(100vh - 54px);
    max-height: 800px;
}

.formContainer{
    margin: 0 auto;
    display: flex;
    align-items: center;
    flex-direction: column;
}
.reachOutText{
    margin-bottom: 0;
    color: #555;
    font-weight: 500;
}
.hidden{
    opacity: 0;
}
.visible{
    color:#555;
    font-weight: 500;
    opacity: 1;
    transition: opacity 200ms ease-in-out;
}
@media screen and (max-width: 600px) {
    .reachOutText{
        display: none;
    }
}
